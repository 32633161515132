// eslint-disable-next-line no-unused-vars
import timerWorker from "./services/Helpers/timer";
// eslint-disable-next-line no-unused-vars
import label from "./services/Translation/lang";
import "./App.css";
import {MemoryRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Main/Header";
import PlayComponent from "./pages/PlayComponent";
import FastBallsTransport from "./services/Networking/FastBallsTransport";
import {getInitUrlParams} from "./services/UrlParamsLoader/UrlParamsLoader";
import {useEffect, useState} from "react";
import storage from "./services/Storage/Storage";
import GameRules from "./components/GameRules/GameRules";
import soundEffect from "./services/Sounds/SoundFxPlayer";
import musicPlayer from "./services/Sounds/MusicPlayer";
import Disconnected from "./components/PopUps/Disconnected";
import NotificationPopUp from "./components/PopUps/NotificationPopUp";
import {ToastContainer} from "react-toastify";
import AskFS from "./components/PopUps/AskFS";
import "react-toastify/dist/ReactToastify.css";
import FullScreenClock from "./components/Clock/FullScreenClock";
// import { checkPreventChangeNickname } from "./components/Avatar/Avatar";

/**
 * @description
 * Main deps class for Elbet FastBalls 35.
 * Features handled intro app.js:
 * - App.js hold Header and PlayComponent.
 * - Loading hackertimer for better performance.
 * - Create networking instance object (net).
 * - Handle Music and Sounds - firstTouchEvent.
 * - Disconnect event handler
 * - urlParams
 * - isAppConnected
 * - notification
 * Own direct Renders:
 * - <Header/>
 * - <PlayComponent/>
 * - <GameRules/>
 * - <Disconnected/>
 * - <NotificationPopUp/>
 * - <ToastContainer/>
 */
function App() {
  /**
   * @description
   * Main net & UrlParams
   */
  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const [isAppConnected, setIsAppConnected] = useState(false);
  const [applicationVersion] = useState("1.2.12 PROD New features: OG-1028 + OG-1046 + OG-1050");
  const [net, setNet] = useState({});

  /**
   * @description
   * Test fix lost focus.
   */
  let hidden, visibilityChange;
  if(typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if(typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if(typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  let handleVisibilityChange = () => {
    if(!document[hidden]) {
      // eslint-disable-next-line no-restricted-globals
      let cur = new Date().getTime(), mem = storage.get('v_back');
      let detDiff = cur - mem;
      console.log('diff = ', detDiff);
      if(detDiff > 3 * 60000) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    } else {
      storage.set('v_back', new Date().getTime());
      console.log('Visibillity lost', new Date().getTime())
    }
  }

  /**
   * @description
   * Main menu staff, related with Header component
   */
  const [showUsernameComp, setShowUsernameComp] = useState(false);
  const [isNicknameAdded, setIsNicknameAdded] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [promoBalance, setPromoBalance] = useState(0.0);
  const [optionMusic, setOptionMusic] = useState(false);
  const [optionSound, setOptionSound] = useState(false);
  const [audioFirstTouch, setAudioFirstTouch] = useState(false);
  const [mainMenuVisibility, setMainMenuVisibility] = useState(false);
  const [avatarMenuVisibility, setAvatarMenuVisibility] = useState(false);
  const [JP1MenuVisibility, setJP1MenuVisibility] = useState(false);
  const [JP2MenuVisibility, setJP2MenuVisibility] = useState(false);
  const [JP3MenuVisibility, setJP3MenuVisibility] = useState(false);
  const [showChangeAvatar, setShowChangeAvatar] = useState(false);
  const [gameRulesVisibility, setGameRulesVisibility] = useState(false);
  const [disconnectedVisibility, setDisconnectedVisibility] = useState(false);
  const [notificationPopUpVisibility, setNotificationPopUpVisibility] =
    useState(false);
  const [notificationAksFSVisibility, setNotificationAksFSVisibility] =
    useState(false);
  const [notification, setNotification] = useState({});

  const [notificationFSClockVisibility, setNotificationFSClockVisibility] =
    useState(false);
  
  const [rtp, setRtp] = useState(95);
  const [bonusRtp, setBonusRtp] = useState(0);
  const [totalRtp, setTotalRtp] = useState(0);

  const [checkMinBet, setCheckMinBet] = useState(0.0);

  const firstTouchEvent = () => {
    if(storage.get("music") === true) musicPlayer.play("theme");
    clearTouchEvent();
  };

  const onConnected = () => {
    if(disconnectedVisibility === true) setIsAppConnected(false);
    setDisconnectedVisibility(false);
    storage.set("IsConnected", true);

    // attach fake jpw
    window.addEventListener('fake-jpw', onFakeJPW)
  };

  const onFakeJPW = (e) => {
    console.log('TEST JPW', e.detail);
    setNotification({
      msg: e.detail.msg,
      type: e.detail.type
    });
    setNotificationPopUpVisibility(true);

    // let fakeJPW = new CustomEvent('fake-jpw', {
    //   detail: {
    //     type: 'JACKPOTWINNER',
    //     msg: 'JACKPOTWINNER ;1234'
    //   }
    // })
    // dispatchEvent(fakeJPW);
  }

  const onLoseConn = () => {
    musicPlayer.stop("theme");
    storage.set("IsConnected", false);
    setDisconnectedVisibility(true);
  };

  const onNotification = (e) => {
    setNotification(e.detail);
    setNotificationPopUpVisibility(true);
  };

  const onBalanceUpdate = (e) => {
    setBalance(e.detail.balance);
    setPromoBalance(e.detail.promobalance);
  };

  const clearTouchEvent = () => {
    document.removeEventListener("click", firstTouchEvent);
  };

  // Ubacen asyn wait radi multilang undefined slucaj
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if(localStorage.getItem('username') !== null) {
      setIsNicknameAdded(true);
    }

    if(isAppConnected === false && isNicknameAdded === true) {
      console.log(
        `%cGIT TAG: v1.0.1 ElbetFastBalls %cVersion ${applicationVersion}`,
        "color: #fdd; font-size:30px;text-shadow: 2px 2px 4px #0d6efd, 4px 4px 4px #d63384, 6px 6px 4px #cb1a20, 8px 8px 4px #20c997;",
        "color: #fff; font-size:16px;text-shadow: 2px 2px 2px #0d6efd, 4px 4px 2px #d63384"
      );

      // Handle page visibility change
      document.addEventListener(visibilityChange, handleVisibilityChange, false);

			sessionStorage.removeItem('amountFormat');
			sessionStorage.removeItem('fixedBet');
      storage.remove("lastPlayedTicked");
      storage.remove("autoplay");
      storage.remove("ticketNumbers");
      storage.set("autoPlayCurrentRound", 0);
      storage.set("IsConnected", false);
      storage.set("limitAllBets", 0);
      storage.set('dontCheckBackToMin', true);

      window.addEventListener("on-direct-message", onNotification);
      window.addEventListener("disconnected", onLoseConn);
      window.addEventListener("connected", onConnected);
      window.addEventListener("balance-update", onBalanceUpdate);

      let net_ = new FastBallsTransport(await getInitUrlParams(), 1000, false);
      setNet(net_);
      soundEffect.setNet(net_);
      soundEffect.allVolume(0.1);
      musicPlayer.volume('theme', 0.15);
      setIsAppConnected(true);
      // initializeShareIntervalAfterReload();

      document.addEventListener(
        "mousedown",
        (e) => {
          let overMainMenu = false,
            avatarmenu = false,
            JPmenu1 = false,
            JPmenu2 = false,
            JPmenu3 = false;

          // multiplatform fix
          var path = e.path || (e.composedPath && e.composedPath());
          path.forEach((p) => {
            if(typeof p.getAttribute !== "undefined") {
              if(p.getAttribute("data-mainmenu") !== null) {
                overMainMenu = true;
              } else if(p.getAttribute("data-avatarmenu") !== null) {
                avatarmenu = true;
              } else if(p.getAttribute("data-jackpotmenu") !== null) {
                JPmenu1 = true;
              } else if(p.getAttribute("data-jackpotmenu2") !== null) {
                JPmenu2 = true;
              } else if(p.getAttribute("data-jackpotmenu3") !== null) {
                JPmenu3 = true;
              }
            }
          });
          if(!overMainMenu) setMainMenuVisibility(mainMenuVisibility);
          if(!avatarmenu) setAvatarMenuVisibility(avatarMenuVisibility);
          if(!JPmenu1) setJP1MenuVisibility(JP1MenuVisibility);
          if(!JPmenu2) setJP2MenuVisibility(JP2MenuVisibility);
          if(!JPmenu3) setJP3MenuVisibility(JP3MenuVisibility);
        },
        {passive: true}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationVersion, isAppConnected, isNicknameAdded]);

  useEffect(() => {
    if(audioFirstTouch === false) {
      document.addEventListener("click", firstTouchEvent, {passive: true});
      setAudioFirstTouch(true);
      if(urlParams.get("fullScreen") === 'true') {
        setTimeout(() => {
          console.log('First touch fullscreen activated.', label.t('fullscreenAsk'))
          setNotificationAksFSVisibility(true);
        }, 150)
      }
    }
    if(optionMusic === true) musicPlayer.play("theme");
    if(optionMusic === false) musicPlayer.stop("theme");
    if(typeof net.sound !== "undefined") {
      if(optionSound === true) net.sound = true;
      if(optionSound === false) net.sound = false;
    }
    // console.log("What is net.sound => ", optionMusic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionMusic, optionSound, audioFirstTouch]);

  useEffect(() => {
    // checkPreventChangeNickname()
    // console.log("test checkPreventChangeNickname")
    setShowChangeAvatar(false);
  }, [net.avatarId]);

  return (
    <MemoryRouter>
      <div className="App full-height">
        <ToastContainer />
        <Header
          net={net}
          balance={balance}
          promoBalance={promoBalance}
          setPromoBalance={setPromoBalance}
          checkMinBet={checkMinBet}
          optionMusic={optionMusic}
          optionSound={optionSound}
          setOptionSound={setOptionSound}
          setOptionMusic={setOptionMusic}
          isNicknameAdded={isNicknameAdded}
          setIsNicknameAdded={setIsNicknameAdded}
          showUsernameComp={showUsernameComp}
          setShowUsernameComp={setShowUsernameComp}
          setShowChangeAvatar={setShowChangeAvatar}
          mainMenuVisibility={mainMenuVisibility}
          setMainMenuVisibility={setMainMenuVisibility}
          gameRulesVisibility={gameRulesVisibility}
          avatarMenuVisibility={avatarMenuVisibility}
          setAvatarMenuVisibility={setAvatarMenuVisibility}
          setGameRulesVisibility={setGameRulesVisibility}
        />
        <Routes>
          <Route
            path="/"
            element={
              <PlayComponent
                version={version}
                net={net}
                setRtp={setRtp}
                setBonusRtp={setBonusRtp}
                setTotalRtp={setTotalRtp}
                setBalance={setBalance}
                balance={balance}
                promoBalance={promoBalance}
                setCheckMinBet={setCheckMinBet}
                setPromoBalance={setPromoBalance}
                isNicknameAdded={isNicknameAdded}
                showUsernameComp={showUsernameComp}
                setShowUsernameComp={setShowUsernameComp}
                setIsNicknameAdded={setIsNicknameAdded}
                setOptionMusic={setOptionMusic}
                setOptionSound={setOptionSound}
                showChangeAvatar={showChangeAvatar}
                setShowChangeAvatar={setShowChangeAvatar}
                JP1MenuVisibility={JP1MenuVisibility}
                setJP1MenuVisibility={setJP1MenuVisibility}
                JP2MenuVisibility={JP2MenuVisibility}
                setJP2MenuVisibility={setJP2MenuVisibility}
                JP3MenuVisibility={JP3MenuVisibility}
                setJP3MenuVisibility={setJP3MenuVisibility}
              />
            }
          />
        </Routes>
        {gameRulesVisibility === true ? (
          <GameRules
            rtp={rtp}
            bonusRtp={bonusRtp}
            totalRtp={totalRtp}
            rtp2={" 94.38%"}
            setGameRulesVisibility={setGameRulesVisibility}
            gameRulesVisibility={gameRulesVisibility}
          />
        ) : null}
        {disconnectedVisibility === true ? (
          <Disconnected label={label} />
        ) : null}

        {notificationAksFSVisibility === true ? (
          <AskFS
            setNotificationAksFSVisibility={setNotificationAksFSVisibility}
            setNotificationFSClockVisibility={setNotificationFSClockVisibility}
            label={label}
          />
        ) : null}

        {notificationPopUpVisibility === true ? (
          <NotificationPopUp
            net={net}
            notification={notification}
            setNotificationPopUpVisibility={setNotificationPopUpVisibility}
            label={label}
            promoBalance={promoBalance}
            setPromoBalance={setPromoBalance}
          />
        ) : null}

        {notificationFSClockVisibility === true ? (
          <FullScreenClock
            setNotificationFSClockVisibility={setNotificationFSClockVisibility}
            label={label}
          />
        ) : null}
      </div>
    </MemoryRouter>
  );
}

export default App;
